import { Controller } from "stimulus";
import useEventController from "../../../frontend/composables/use_event_controller";

export default class extends Controller {
  static targets = ["allCheckbox", "selectCheckbox", "selectedValues", "someSelected", "totalCount", "selectedLabel"];

  static values = { selectedOne: String, selectedOther: String, selectedAll: String, allPages: Boolean };

  initialize() {
    useEventController(this);
  }

  selectedValuesTargetConnected() {
    this.setStore(this.storeDefault());
  }

  someSelectedTargetConnected() {
    this.selectedLabelElement = this.someSelectedTarget.querySelector("[role=label]");
  }

  allCheckboxTargetConnected() {
    this.listenOn(this.allCheckboxTarget, "change", () => this.allCheckboxChanged());
  }

  totalCountTargetConnected() {
    this.totalCount = Number.parseInt(this.totalCountTarget.dataset.totalCount, 10);
    this.selectionChanged();
  }

  selectCheckboxTargetConnected(checkbox) {
    const store = this.store();
    this.checkboxChecked(checkbox, store === true || store.includes(checkbox.value));
    this.listenOn(checkbox, "change", (event) => this.checkboxChanged(event));
  }

  selectCheckboxTargetDisconnected(checkbox) {
    this.listenOff(checkbox, "change");
  }

  deselectAll() {
    this.setStore(this.storeDefault());
    this.allCheckboxChecked(false);
    this.allCheckboxChanged();
  }

  checkboxChanged(event) {
    const checkbox = event.target;
    if (checkbox.checked) {
      this.addValue(checkbox.value);
    } else {
      this.allCheckboxChecked(false, false);
      if (this.store() === true) {
        this.setStore(this.selectCheckboxTargets.filter((cb) => cb.checked).map((cb) => cb.value));
      } else {
        this.removeValue(checkbox.value);
      }
    }

    this.selectionChanged();
  }

  allCheckboxChanged() {
    this.selectCheckboxTargets.forEach((checkbox) => this.checkboxChecked(checkbox, this.allCheckboxChecked()));
    if (this.allPagesValue) {
      this.setStore(this.storeDefault());
    }
    this.selectionChanged();
  }

  selectionChanged() {
    const count = this.store() === true ? Infinity : this.store().length;
    this.configureClasses(count);

    if (count === Infinity && (this.totalCount === undefined || Number.isNaN(this.totalCount))) {
      this.selectedLabelElement.textContent = this.selectedAllValue;
    } else if (count > 0) {
      const displayCount = count === Infinity ? this.totalCount : count;
      this.selectedLabelElement.textContent =
        displayCount === 1 ? this.selectedOneValue : this.selectedOtherValue.replace("%{count}", displayCount);
    }
  }

  configureClasses(count) {
    this.element.classList.toggle("multiselected", count);
  }

  storeDefault() {
    return this.allCheckboxChecked() ? true : [];
  }

  store() {
    return JSON.parse(this.selectedValuesTarget.value);
  }

  setStore(store) {
    const value = JSON.stringify(store);
    this.selectedValuesTargets.forEach((target) => {
      target.value = value;
    });
  }

  addValue(value) {
    let store = this.store();
    if (store === true) {
      store = [];
    }
    store.push(value);
    this.setStore(store);
  }

  removeValue(value) {
    const store = this.store();
    const index = store.indexOf(value);
    if (index !== -1) store.splice(index, 1);
    this.setStore(store);
  }

  allCheckboxChecked(check = undefined, notify = true) {
    if (!this.hasAllCheckboxTarget) return false;

    if (check !== undefined) {
      this.checkboxChecked(this.allCheckboxTarget, check, notify);
    }

    return this.allCheckboxTarget.checked;
  }

  checkboxChecked(checkbox, check, notify = true) {
    if (checkbox.checked !== check) {
      if (notify) {
        checkbox.click();
      } else {
        checkbox.checked = check;
      }
    }
  }

  disable() {
    this.allCheckboxTarget.setAttribute("disabled", true);
    this.selectCheckboxTargets.forEach((input) => input.setAttribute("disabled", true));
  }

  enable() {
    this.allCheckboxTarget.removeAttribute("disabled");
    this.selectCheckboxTargets.forEach((input) => input.removeAttribute("disabled"));
  }
}
