/* eslint-env browser */
import { Controller } from "stimulus";

export default class extends Controller {
  static outlets = ["statements--editor"];

  static targets = ["order"];

  orderTargetConnected(element) {
    const checkbox = element.querySelector("input[type='checkbox']");
    const amount = element.querySelector("input[type='number']");

    const selectedOrder = this.statementsEditorOutlet.selectedOrdersValue.find(
      (order) => order.order_id === checkbox.dataset.orderId,
    );

    // eslint-disable-next-line no-underscore-dangle
    if (!selectedOrder || selectedOrder._destroy === "1") {
      checkbox.checked = false;
      amount.value = "0.0";
      return;
    }

    checkbox.checked = true;
    amount.value = selectedOrder.amount;
  }

  allOrdersToggled(event) {
    this.orderTargets.forEach((target) => {
      const checkbox = target.querySelector("input[type='checkbox']");

      if (checkbox.checked === event.target.checked) return;

      checkbox.checked = event.target.checked;

      this.orderToggled({ target: checkbox });
    });
  }

  orderToggled(event) {
    const target = event.target.closest("tr");
    const input = target.querySelector("input[type='number']");

    input.setAttribute("min", event.target.checked ? "2" : "0");

    this.dispatchOrderUpdate(event.target, event.target.dataset, event.target.checked);
  }

  orderAmountUpdated(event) {
    const target = event.target.closest("tr");
    const checkbox = target.querySelector("[type='checkbox']");

    checkbox.dataset.amount = event.target.valueAsNumber || "0.0";

    if (event.target.valueAsNumber) {
      checkbox.checked = true;
    }

    event.target.setAttribute("min", checkbox.checked ? "2" : "0");

    this.dispatchOrderUpdate(event.target, checkbox.dataset, checkbox.checked);
  }

  dispatchOrderUpdate(target, order, checked) {
    this.dispatch("update-order", { target, detail: { order, checked } });
  }
}
