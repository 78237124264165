/* eslint-env browser */
import { Modal } from "tailwindcss-stimulus-components";

export default class extends Modal {
  static targets = ["reload"];

  static values = {
    backdropColor: { type: String, default: "rgba(0, 0, 0, 0.8)" },
    restoreScroll: { type: Boolean, default: true },
    scrollTo: String,
  };

  initialLoadingContent = {};

  reloadTargetConnected(target) {
    this.initialLoadingContent[target] = target.innerHTML;
  }

  open(event) {
    super.open(event);

    // Once open, if set, scroll to given scrollTo target
    if (event.params.scrollTo) {
      this.containerTarget.querySelector(event.params.scrollTo).scrollIntoView();
    }

    this.reloadTargets.forEach((target) => {
      target.innerHTML = this.initialLoadingContent[target];
      target.reload();
    });
  }
}
