/* eslint-env browser */

import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import useEventController from "../../composables/use_event_controller";

export default class extends Controller {
  static targets = ["orderStatusFilter"];

  connect() {
    useEventController(this);

    this.listenOnDocument("turbo:frame-load", this.resultsFrameLoaded.bind(this));
  }

  resultsFrameLoaded(event) {
    event.target.querySelectorAll("a[data-component^='see_all_']").forEach((link) => {
      if (link === null) {
        return;
      }

      const location = new URL(link.href);

      link.setAttribute("href", `${location.pathname}${window.location.search}`);
    });
  }

  orderStatusFilterTargetConnected(element) {
    if (!new URL(window.location).searchParams.has("filters[orderstatus_ids]")) {
      return;
    }

    const selected =
      element.closest("[data-ui--dropdown--dropdown-selected-value]")?.dataset?.dropdownMenuSelectedValue?.split(".") ??
      [];

    element.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      checkbox.checked = selected.includes(checkbox.value);
    });
  }

  orderstatusDropdownClosed(event) {
    const orderStatuses = event.target.querySelectorAll("input[type='checkbox']:checked");
    const totalOrderStatuses = event.target.querySelectorAll("input[type='checkbox']").length;
    const isDefaultLabel = orderStatuses.length === totalOrderStatuses;

    const params = new URLSearchParams(window.location.search);
    params.set(
      "filters[orderstatus_ids]",
      Array.from(orderStatuses)
        .map((element) => element.value)
        .join("."),
    );

    const url = `${window.location.pathname}?${params.toString()}`;

    window.history.pushState(window.history.state, null, url);

    Turbo.visit(url, { frame: "results_frame" });

    const label = isDefaultLabel ? "All order statuses" : `${orderStatuses.length} order statuses selected`;

    window.dispatchEvent(
      new CustomEvent("dropdown:update", {
        target: this.element,
        detail: {
          label,
          isDefaultLabel,
        },
      }),
    );
  }

  toggleOrderStatuses(event) {
    const { enable } = event.params;

    this.orderStatusFilterTarget.querySelectorAll("input[type='checkbox']").forEach((element) => {
      element.checked = enable;
    });
  }
}
