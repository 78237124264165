/* eslint-env browser */

import { Controller } from "stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static debounces = [{ name: "search", wait: 300 }];

  static targets = ["input", "option", "defaultLabel"];

  static values = { searchUrl: String };

  connect() {
    useDebounce(this);
    this.search();
  }

  inputTargetConnected() {
    useDebounce(this, { wait: 300 });
  }

  optionTargetConnected(option) {
    option.style.display = "none";
  }

  async search() {
    const params = new URLSearchParams({ query: this.inputTarget.value, limit: 4 });
    const response = await fetch(`${this.searchUrlValue}?${params}`, {
      method: "GET",
      headers: {
        "X-CSRF-Token": document.head.querySelector("meta[name='csrf-token']")?.content,
      },
    });

    if (response.ok) {
      this.optionTargets.forEach((option) => {
        option.style.display = "none";
      });

      const customers = await response.json();
      const results = customers.options.slice(0, this.optionTargets.length);
      results.forEach((customer, index) => {
        this.optionTargets[index].style.display = "block";
        this.optionTargets[index].value = customer.value;
        this.optionTargets[index].dataset.value = customer.value;
        this.optionTargets[index].textContent = customer.text;
      });
    }
  }
}
